import {saveAs} from 'file-saver';
import {all, call, fork, put, select, take, takeEvery, takeLatest} from 'redux-saga/effects';

import {
    STORE_ENTERPRISE_INFORMATION,
    updateEnterpriseInformation,
} from './additionalCompanyData/additionalCompanyData.actions';
import {
    loadAdditionalCompanyData,
    watchAdditionalCompanyDataSagas,
} from './additionalCompanyData/additionalCompanyData.sagas';
import * as actions from './company.actions';
import {storeCompany} from './company.actions';
import {COMPANY_PROFILE_TABS} from './company.consts';
import {selectCompany, selectCompanyDocuments, selectUploadingDocumentIds} from './company.selectors';
import {companyDashboardLoaderSaga, watchCompanyDashboardSagas} from './companyDashboard/companyDashboard.sagas';
import {companyDocumentsLoaderSaga, watchCompanyDocumentsSagas} from './companyDocuments/companyDocuments.sagas';
import {getCompanies, setIsLoadingCompanies} from './companyList/companyList.actions';
import {watchCompanyListSagas} from './companyList/companyList.sagas';
import {COMPANY_STATUSES} from './setupCompany/setupCompany.constants';
import {watchSetupCompanySagas} from './setupCompany/setupCompany.sagas';
import {getTrainingInvoice} from './training/training.actions';
import {watchTrainingSagas} from './training/training.sagas';
import {uploadDocumentSaga} from './uploadDocument.saga';
import {selectCurrentCognitoUser} from '../../../features/auth/store/auth.selectors';
import {getInsuranceFlow} from '../../../features/insurance/store/insurance.saga';
import {InvoiceActions} from '../../../features/invoicing/store/invoice.actions';
import {LoadingActions, LoadingTypes} from '../../../features/loading';
import {Toast} from '../../../lib/toast';
import {USER_ROLES} from '../../../utils/user-roles';
import {DOCUMENT_CONTEXTS} from '../../config/constants/documentConstants';
import {
    approveCompanyRequest,
    deleteDocumentRequest,
    getCompanyRequest,
    getDocumentUrls,
    getPersonalCompanyDocumentsRequest,
    getSignedUrlForDocumentRequest,
    registrationPerformedRequest,
    requestEditsRequest,
    saveCompanyInformationDataRequest,
    saveDocumentFlagsRequest,
    saveDocumentsRequest,
    saveNonConvictionDeclarationDataRequest,
    savePersonalInformationDataRequest,
    uploadDocumentRequest,
} from '../api/providers/company/company.provider';
import {isMobileSafari, isSafari} from '../app.helpers';
import {businessAllowanceLoaderSaga} from '../expenses/businessAllowance/businessAllowance.sagas';
import {personalExpensesLoaderSaga} from '../expenses/personalExpenses/personalExpenses.sagas';
import {professionalExpensesLoaderSaga} from '../expenses/professionalExpenses/professionalExpenses.sagas';
import {getFreelancerCompanies} from '../freelancer/freelancer.actions';
import {loadFreelancerAccountSaga} from '../freelancer/freelancer.sagas';
import {selectFreelancerAccount} from '../freelancer/freelancer.selectors';
import {getUser} from '../user/user.actions';

export const getCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingCompany(true));

        const {companyId, freelancerId} = payload;

        const company = yield call(getCompanyRequest, freelancerId, companyId);

        yield put(actions.storeCompany(company));

        return company;
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingCompany(false));
    }
};

const updatePersonalInformationSaga = function* ({payload}) {
    try {
        /**
         * TODO:HIGH Rethink this onSuccess callback.
         * It breaks uni-directional flow, but on the other hand, I don't see any other solution at the moment
         * in order to update local component state.
         */
        const {personalInformation, onSuccess} = payload;

        yield put(actions.setIsUpdatingPersonalInformation(true));

        const company = yield select(selectCompany);

        yield call(savePersonalInformationDataRequest, company.userId, company.id, personalInformation);

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        yield put(storeCompany({
            ...company,
            personalInformation,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingPersonalInformation(false));
    }
};

const updateNonConvictionDeclarationSaga = function* ({payload}) {
    try {
        /**
         * TODO:HIGH Rethink this onSuccess callback.
         * It breaks uni-directional flow, but on the other hand, I don't see any other solution at the moment
         * in order to update local component state.
         */
        const {nonConvictionDeclaration, onSuccess} = payload;

        yield put(actions.setIsUpdatingNonConvictionDeclaration(true));

        const company = yield select(selectCompany);

        yield call(saveNonConvictionDeclarationDataRequest, company.userId, company.id, nonConvictionDeclaration);

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        yield put(storeCompany({
            ...company,
            nonConvictionDeclaration,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingNonConvictionDeclaration(false));
    }
};

const updateCompanyInformationSaga = function* ({payload}) {
    try {
        /**
         * TODO:HIGH Rethink this onSuccess callback.
         * It breaks uni-directional flow, but on the other hand, I don't see any other solution at the moment
         * in order to update local component state.
         */
        const {companyInformation, onSuccess} = payload;

        const company = yield select(selectCompany);

        yield call(saveCompanyInformationDataRequest, company.userId, company.id, companyInformation);

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        yield put(storeCompany({
            ...company,
            companyInformation,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    }
};

const approveCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setIsApprovingCompany(true));

        const company = yield select(selectCompany);

        yield call(approveCompanyRequest, company.userId, company.id);

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(actions.storeCompany({
            ...company,
            status: COMPANY_STATUSES.PENDING_COMPANY_ID,
        }));

        Toast.success('companyApproved');
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsApprovingCompany(false));
    }
};

const requestEditsSaga = function* ({payload}) {
    try {
        yield put(actions.setIsRequestingEdits(true));

        const company = yield select(selectCompany);

        yield call(requestEditsRequest, company.userId, company.id, payload.message);

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(actions.storeCompany({
            ...company,
            status: COMPANY_STATUSES.PENDING_EDITS,
        }));

        Toast.success('editsRequested');
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsRequestingEdits(false));
    }
};

const getCompanyDocumentsSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingCompanyDocuments(true));

        const {freelancerId, companyId} = payload;

        const companyDocuments = yield call(getPersonalCompanyDocumentsRequest, freelancerId, companyId);

        yield put(actions.storeCompanyDocuments(companyDocuments));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingCompanyDocuments(false));
    }
};

export const getCompanyDocumentSaga = function* ({payload}) {
    try {
        const {freelancerId, companyId, documentId, isDownload, isView} = payload;

        let childWindow;

        if ((isSafari || isMobileSafari) && !isDownload) {
            childWindow = window.open('', '_blank');
        }

        if (isView) {
            yield put(LoadingActions.setLoading(LoadingTypes.VIEW_DOCUMENT, true));
        }
        if (isDownload) {
            yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, true));
        }

        const {signedUrl} = yield call(
            getSignedUrlForDocumentRequest,
            freelancerId,
            companyId,
            documentId,
            isDownload,
        );

        if (!signedUrl) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('The document URL is missing.');
        }

        if (isView) {
            yield put(LoadingActions.setLoading(LoadingTypes.VIEW_DOCUMENT, false));
            yield put(InvoiceActions.storeInvoiceUrl(signedUrl));
            return;
        }
        if (isDownload) {
            yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.VIEW_DOCUMENT, false));
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    }
};

const createDocumentSaga = function* ({payload}) {
    try {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds([
            ...uploadingDocumentIds,
            payload.documentId,
        ]));

        const company = yield select(selectCompany);
        const {companyId, documentId, file, onSuccess, subType} = payload;

        const companyDocumentFlags = {
            ...company.personalDocuments,
        };

        const documents = [];

        if (documentId === 'identificationDocument') {
            documents.push({
                doc_type: 'ID_DOC',
                doc_sub_type: subType,
                doc_file_name: file.name,
                doc_size: file.size,
            });

            companyDocumentFlags.identificationDocumentType = subType;
        }

        if (documentId === 'residenceCertificationDocument') {
            documents.push({
                doc_type: 'RESIDENCE_CERT',
                doc_file_name: file.name,
                doc_size: file.size,
            });
        }

        if (documentId === 'hostIdentificationDocument') {
            documents.push({
                doc_type: 'RESIDENCE_CERT_HOST_ID',
                doc_sub_type: subType,
                doc_file_name: file.name,
                doc_size: file.size,
            });

            companyDocumentFlags.hostIdentificationDocumentType = subType;
        }

        if (documentId === 'hostCertificationDocument') {
            documents.push({
                doc_type: 'RESIDENCE_CERT_HOST',
                doc_file_name: file.name,
                doc_size: file.size,
            });
        }

        if (documentId === 'insuranceDocument') {
            documents.push({
                doc_type: 'NIN_DOC',
                doc_sub_type: subType,
                doc_file_name: file.name,
                doc_size: file.size,
            });

            companyDocumentFlags.insuranceDocumentType = subType;
        }

        const signedDocuments = yield call(getDocumentUrls, company.userId, companyId, documents);

        yield call(uploadDocumentRequest, signedDocuments[0].signed_url, file);

        const finalDocuments = signedDocuments.map(document => ({
            document_id: document.document_id,
            doc_type: document.doc_type,
            doc_sub_type: document.doc_sub_type,
            doc_file_name: document.doc_file_name,
        }));

        yield call(saveDocumentsRequest, company.userId, companyId, finalDocuments);

        let {
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        } = yield select(selectCompanyDocuments);

        const documentObject = {
            companyId,
            id: signedDocuments[0].document_id,
            category: signedDocuments[0].doc_category || 'PERSONAL', // TODO:LOW Should be returned by API
            type: signedDocuments[0].doc_type,
            subType,
            fileName: file.name,
        };

        switch (documentId) {
            case 'identificationDocument':
                identificationDocument = documentObject;

                break;

            case 'residenceCertificationDocument':
                residenceCertificationDocument = documentObject;

                break;

            case 'insuranceDocument':
                insuranceDocument = documentObject;

                break;

            case 'hostIdentificationDocument':
                hostIdentificationDocument = documentObject;

                break;

            case 'hostCertificationDocument':
                hostCertificationDocument = documentObject;

                break;
        }

        yield put(actions.storeCompanyDocuments({
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        }));

        yield call(saveDocumentFlagsRequest, company.userId, companyId, {
            ...companyDocumentFlags,
            isUserHostedByThirdParty: !!hostIdentificationDocument && !!hostCertificationDocument,
        });

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        Toast.success('documentUploaded');
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds(
            uploadingDocumentIds.filter(documentId => documentId !== payload.documentId),
        ));
    }
};

const deleteDocumentSaga = function* ({payload}) {
    try {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds([
            ...uploadingDocumentIds,
            payload.documentId,
        ]));

        const currentCognitoUser = yield select(selectCurrentCognitoUser);

        let freelancerId = currentCognitoUser.id;

        if (currentCognitoUser.role !== USER_ROLES.FREELANCER) {
            const freelancerAccount = yield select(selectFreelancerAccount);

            freelancerId = freelancerAccount.id;
        }

        const {companyId, documentId} = payload;

        const company = yield select(selectCompany);

        const companyDocumentFlags = {
            ...company.personalDocuments,
        };

        yield call(
            deleteDocumentRequest,
            freelancerId,
            companyId,
            documentId,
        );

        let {
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        } = yield select(selectCompanyDocuments);

        switch (documentId) {
            case identificationDocument?.id:
                identificationDocument = null;
                companyDocumentFlags.identificationDocumentType = null;

                break;

            case residenceCertificationDocument?.id:
                residenceCertificationDocument = null;

                break;

            case insuranceDocument?.id:
                insuranceDocument = null;
                companyDocumentFlags.insuranceDocumentType = null;

                break;

            case hostIdentificationDocument?.id:
                hostIdentificationDocument = null;
                companyDocumentFlags.hostIdentificationDocumentType = null;

                break;

            case hostCertificationDocument?.id:
                hostCertificationDocument = null;

                break;
        }

        yield put(actions.storeCompanyDocuments({
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        }));

        yield call(saveDocumentFlagsRequest, freelancerId, companyId, {
            ...companyDocumentFlags,
            isUserHostedByThirdParty: !!hostIdentificationDocument && !!hostCertificationDocument,
        });

        Toast.success('documentDeleted');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds(
            uploadingDocumentIds.filter(documentId => documentId !== payload.documentId),
        ));
    }
};

export const companyLoaderSaga = function* (action) {
    yield put(actions.setIsLoadingCompanyProfileView(true));

    try {
        const {params: {companyId, freelancerId, tab}} = action.payload;

        yield put(getFreelancerCompanies(freelancerId));

        yield put(getUser(freelancerId));

        const sagas = [
            [getCompanySaga, {
                payload: {
                    freelancerId,
                    companyId,
                },
            }],
            [loadFreelancerAccountSaga, freelancerId],
        ];

        if (tab === COMPANY_PROFILE_TABS.ADDITIONAL_COMPANY_DATA.id) {
            sagas.push([loadAdditionalCompanyData, freelancerId, companyId]);
        } else if (tab === COMPANY_PROFILE_TABS.COMPANY_INFORMATION.id || !tab) {
            sagas.push([getCompanyDocumentsSaga, {
                payload: {
                    freelancerId,
                    companyId,
                },
            }], [getInsuranceFlow, freelancerId, companyId]);
        } else if (tab === COMPANY_PROFILE_TABS.TRAINING.id) {
            yield put(getTrainingInvoice(freelancerId, companyId));
        } else if (tab === COMPANY_PROFILE_TABS.INVOICING.id) {
            yield put(InvoiceActions.getAdminInvoice({
                params: {
                    freelancerId,
                    companyId,
                },
            }));
        } else if (tab === COMPANY_PROFILE_TABS.DOCUMENTS.id) {
            sagas.push([companyDocumentsLoaderSaga, {
                payload: {
                    params: {
                        freelancerId,
                        companyId,
                    },
                    documentContext: DOCUMENT_CONTEXTS.DATABASE,
                },
            }]);
        } else if (tab === COMPANY_PROFILE_TABS.BUSINESS_KILOMETERS_ALLOWANCE.id) {
            sagas.push([businessAllowanceLoaderSaga, {
                payload: {
                    params: {
                        freelancerId,
                        companyId,
                    },
                },
            }]);
        } else if (tab === COMPANY_PROFILE_TABS.PERSONAL_EXPENSES.id) {
            sagas.push([personalExpensesLoaderSaga, {
                payload: {
                    params: {
                        freelancerId,
                        companyId,
                    },
                },
            }]);
        } else if (tab === COMPANY_PROFILE_TABS.DASHBOARD.id) {
            sagas.push([companyDashboardLoaderSaga, {
                payload: {
                    params: {
                        freelancerId,
                        companyId,
                    },
                },
            }]);
        } else if (tab === COMPANY_PROFILE_TABS.PROFESSIONAL_EXPENSES.id) {
            sagas.push([professionalExpensesLoaderSaga, {
                payload: {
                    params: {
                        freelancerId,
                        companyId,
                    },
                },
            }]);
        }

        yield all(sagas.map(sagaConfig => call(sagaConfig[0], ...sagaConfig.slice(1))));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingCompanyProfileView(false));
    }
};

const updateCompanyInformationWithActivityStartDateSaga = function* ({payload}) {
    yield put(actions.setIsUpdatingCompanyInformation(true));

    const {companyId, freelancerId, companyInformation, activityStartDate, onSuccess} = payload;

    yield put(actions.updateCompanyInformation(companyInformation));

    yield take(actions.STORE_COMPANY);

    const company = yield select(selectCompany);

    const formData = {
        activityStartDate: activityStartDate,
    };

    if (company?.status && company.enterpriseInformation
    // && HAS_AVAILABLE_COMPANY_EDIT_ADDRESS_STATUSES.indexOf(company.status) !== -1
    ) {
        formData.headquartersCity = company.enterpriseInformation.headquarters_city;
        formData.headquartersCountry = company.enterpriseInformation.headquarters_country;
        formData.headquartersStreet = company.enterpriseInformation.headquarters_street;
        formData.headquartersStreetNumber = company.enterpriseInformation.headquarters_street_number;
        formData.headquartersZip = company.enterpriseInformation.headquarters_zip;
    }

    yield put(updateEnterpriseInformation(freelancerId, companyId, formData, onSuccess));

    yield take(STORE_ENTERPRISE_INFORMATION);

    yield put(actions.setIsUpdatingCompanyInformation(false));
};

const setRegistrationPerformedSaga = function* ({payload}) {
    try {
        yield put(setIsLoadingCompanies(true));

        yield call(registrationPerformedRequest, payload.freelancerId, payload.companyId);

        yield put(getCompanies());

        Toast.success('registrationPerformed');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('registrationPerformedError');

        yield put(setIsLoadingCompanies(false));
    }
};

export const loadCompany = function* (freelancerId, companyId) {
    yield call(getCompanySaga, {
        payload: {
            freelancerId,
            companyId,
        },
    });
};

export const watchCompanySagas = function* watchCompanySagas() {
    yield all([
        fork(watchSetupCompanySagas),
        fork(watchCompanyListSagas),
        fork(watchCompanyDocumentsSagas),
        fork(watchAdditionalCompanyDataSagas),
        fork(watchTrainingSagas),
        fork(watchCompanyDashboardSagas),
        takeLatest(actions.GET_COMPANY, getCompanySaga),
        takeLatest(actions.UPDATE_PERSONAL_INFORMATION, updatePersonalInformationSaga),
        takeLatest(actions.UPDATE_NON_CONVICTION_DECLARATION, updateNonConvictionDeclarationSaga),
        takeLatest(actions.UPDATE_COMPANY_INFORMATION, updateCompanyInformationSaga),
        takeLatest(actions.APPROVE_COMPANY, approveCompanySaga),
        takeLatest(actions.REQUEST_EDITS, requestEditsSaga),
        takeLatest(actions.GET_PERSONAL_COMPANY_DOCUMENTS, getCompanyDocumentsSaga),
        takeLatest(actions.GET_COMPANY_DOCUMENT, getCompanyDocumentSaga),
        takeEvery(actions.UPLOAD_DOCUMENT, uploadDocumentSaga),
        takeEvery(actions.CREATE_DOCUMENT, createDocumentSaga),
        takeEvery(actions.DELETE_DOCUMENT, deleteDocumentSaga),
        takeLatest(actions.SET_REGISTRATION_PERFORMED, setRegistrationPerformedSaga),
        takeLatest(
            actions.UPDATE_COMPANY_INFORMATION_WITH_ACTIVITY_START_DATE,
            updateCompanyInformationWithActivityStartDateSaga,
        ),
    ]);
};
